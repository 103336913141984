import CookieBanner, { Cookies } from 'react-cookie-banner';
import { useCallback, useEffect, useState } from 'react';

import styles from './index.module.scss';

const cookies = new Cookies();
const Cookie = () => {
  const [cookie, setCookie] = useState(null);
  const [isShowCookie, setShowCookie] = useState(false);

  useEffect(() => {
    cookies.get('accepts-cookies');
    if (Object.keys(cookies.cookies).length === 0) {
      setShowCookie(true);
      setCookie(cookie);
    } else {
      setShowCookie(false);
    }
  }, [cookie]);

  const resetCookies = useCallback(() => {
    cookies.remove('accepts-cookies');
    setShowCookie(false);
  }, []);

  const toggleDismissOnClick = useCallback(() => {
    setShowCookie(false);
    cookies.set('accepts-cookies', true);
  }, []);

  return (
    <>
      {isShowCookie ? (
        <div className={styles.block}>
          <div className={styles.cookieBtn}>
            <button
              type="button"
              onClick={resetCookies}
              style={{ marginLeft: 20 }}
            >
              Nicht einverstanden
            </button>
            <button
              type="button"
              onClick={toggleDismissOnClick}
              style={{ marginLeft: 20 }}
            >
              Einverstanden
            </button>
          </div>
          <CookieBanner
            className={styles.banner}
            /* eslint-disable-next-line max-len */
            message="Diese Webseite verwendet Cookies, um das Nutzerverhalten zu analysieren und unsere Dienste zu verbessern"
            onAccept={() => toggleDismissOnClick}
            cookies={cookies}
            cookieExpiration={{}}
          />
        </div>
      ) : null}
    </>
  );
};

export default Cookie;
